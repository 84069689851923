<template>
  <v-card class="organization-item">
    <v-card-title class="justify-start pointer" @click="$emit('view', item)">
      <img v-if="item.logoUrl" :alt="item.logoUrl" :src="item.logoUrl" class="d-block mx-4" width="50px" />
      {{ item.name }}
    </v-card-title>
    <v-col
      :class="{ 'flex-column align-baseline': $vuetify.breakpoint.xsOnly }"
      class="ps_input-group col-lg-6 col-12 d-flex flex-row align-center"
    >
      <span class="d-flex align-center text-capitalize">
        <v-icon class="mr-2" color="blue" small>mdi-information-outline</v-icon>
        Visit Can’t Be Canceled Earlier Than</span
      >
      <span>
        &nbsp;-&nbsp;
        <b>{{ item.scheduleToCancelLimit || "" }}</b>
      </span>
    </v-col>
    <v-col
      :class="{ 'flex-column align-baseline': $vuetify.breakpoint.xsOnly }"
      class="ps_input-group col-lg-6 col-12 d-flex flex-row align-center"
    >
      <span class="d-flex align-center text-capitalize">
        <v-icon class="mr-2" color="blue" small>mdi-information-outline</v-icon>
        Patient can request no later than</span
      >
      <span>
        &nbsp;-&nbsp; <b>{{ item.scheduleToStartLimit || "" }}</b>
      </span>
    </v-col>
    <v-col
      :class="{ 'flex-column align-baseline': $vuetify.breakpoint.xsOnly }"
      class="ps_input-group col-lg-6 col-12 d-flex flex-row align-center"
    >
      <span class="d-flex align-center text-capitalize">
        <v-icon class="mr-2" color="blue" small>mdi-information-outline</v-icon>
        Visit Duration</span
      >
      <span
        >&nbsp;-&nbsp; <b>{{ item.slotDuration || "" }}</b></span
      >
    </v-col>
    <v-col
      :class="{ 'flex-column align-baseline': $vuetify.breakpoint.xsOnly }"
      class="ps_input-group col-lg-6 col-12 d-flex flex-row align-center"
    >
      <span class="d-flex align-center text-capitalize">
        <v-icon class="mr-2" color="blue" small>mdi-information-outline</v-icon>
        How far in to the future visit schedules can be?</span
      >
      <span>
        &nbsp;-&nbsp; <b>{{ item.scheduleHorizonLimit || "" }} days</b>
      </span>
    </v-col>
    <v-divider></v-divider>
    <v-card-actions class="d-flex justify-end">
      <IconicButton
        v-if="!hideViewSchedule"
        left-icon="mdi-calendar"
        size="small"
        text="View schedule"
        @onClick="$emit('viewSchedule', item)"
      />
      <IconicButton left-icon="mdi-pencil-outline" size="small" text="Modify" @onClick="$emit('edit', item)" />
      <IconicButton left-icon="mdi-eye" size="small" text="View" @onClick="$emit('view', item)" />
    </v-card-actions>
  </v-card>
</template>
<script>
import IconicButton from "@/components/uikit/IconicButton.vue";

export default {
  name: "OrganizationInfo",
  components: { IconicButton },
  props: {
    hideViewSchedule: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
